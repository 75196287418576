<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 4</h1>
      <h3>Integrate Additional Source</h3>
      <p class="text-left">Optional stage to integrate additional source (e.g. short read RTD)<br/>
      Step 1: Identify novel transcript from source<br/>
      Step 2: Identify intronic and chimeric gene models<br/>
      Step 3: Integrate transcripts to HTSM RTD<br/>
      Step 4: Summary of RTD diversity and export RTD</p>
      
      <p class="m-0 font-weight-bold">Select the folder which contains the relevant data for integrating:</p>
      <b-row><b-col>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
    
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected">
        </folder-browser>

        <p class="m-0 text-left">Parameters:
          <ul>
            <li>Chimeric gene overlap percent: If the overlap of two gene models is less than x%, they are treated as separated gene models.</li>
          </ul>
        </p>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Chimeric gene overlap percent:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="geneOverlapTip">
              <b-form-input id="geneOverlapInput" v-model="geneOverlap" type="number" min="0" step="0.01" max="1" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-form-checkbox
          id="NameCheck"
          v-model="nameCheck"
          name="NameCheck"
        >
          Tick if you want to use a custom name for the results folder.
        </b-form-checkbox>

        <b-row v-if="nameCheck" class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box">
              <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
      </b-form>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: "assembly",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      geneOverlap: 0.05,
      showgeneOverlap: false,
      geneOverlapTip: "If the overlap of two gene models < x%, they are treated as separated gene models.",
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=lr4",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        this.folderID = this.$route.params.resubmitData.filterFolder;
        this.geneOverlap = this.$route.params.resubmitData.geneOverlap;

        if(this.folderID !== undefined && this.folderID !== null)
        {
          this.folders.forEach(folder => {
            if((folder.id.localeCompare(this.folderID) == 0))
            {
              this.folderSelected(folder);
            }
          });
        }
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/longread/addSource", {
        params: {
          token: this.token,
          filterFolderID: this.selectedFolder.id,
          geneOverlap: this.geneOverlap,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.folderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
</style>